<template>
  <div class="login">

    <!-- <Notification msg="Voordat we verder gaan.... heb je je scherm gedeeld ?!?!?!" /> -->

    <!-- <h1>SUPRISE!!</h1> -->

    <div v-if="shareScreen.display">
      <h1>{{shareScreen.displayMessage}}</h1>
      <br/><br/>
      <button v-show="shareScreen.displayMessage.length == shareScreen.message.length" v-on:click="hideShareScreen()">Ga verder ></button>
    </div>


    <div v-if="!shareScreen.display">

      <h3>Ik ben heel benieuwd met wie ik te maken heb. Vul je TNXTO emailadres in:</h3>

      <input type="email" v-model="emailInput" />

    </div>
    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>

<script>
// @ is an alias to /src
//import Notification from '@/components/Notification.vue'

export default {
  name: 'Login',
  components: {
    //Notification
  },
  data: function () {
    return {
      shareScreen: {
        display: true,
        message: "Voordat we verder gaan...... Deel eerst je scherm via Teams!!!!",
        displayMessage: "",
        counter: 0
      },
      emailInput: "",
    }
  },
  methods: {
    hideShareScreen: function() {
      this.shareScreen.display = false;
    }
  },
  watch: {
    // whenever question changes, this function will run
    emailInput: function (val) {
      if(val.length > 3) {
        var user = this.$root.colleagues.find(x => x.email == val.toLowerCase());
        if(!user) {
          user = this.$root.colleagues.find(x => x.email == val.toLowerCase() + "@tnxto.com");
        }
        if(user) {
          this.$root.user = user;
          this.$router.push("welcome");
        }
      }
    }
  },
  mounted() {
    //var self = this;
    setInterval(() => {
      //console.log(this.shareScreen.message);
      if(this.shareScreen.displayMessage.length < this.shareScreen.message.length) {
        this.shareScreen.displayMessage += this.shareScreen.message[this.shareScreen.counter];
        this.shareScreen.counter++;
      //console.log("x");
      }
    }, 200);
  }
}
</script>

<style scoped>
  input[type="email"] {
    background-color: #F38337;
    color: #053562;
    width: 250px;
    height: 25px;
    border: none;
    font-size: 20px;
    border-radius: 10px;
    border: 2px solid #053562;
  }

  input[type="email"]:focus {
    background-color: white;
    border: 2px solid #053562;
  }
</style>